const EARTH={};
EARTH.circleR=320;


class Earth{
	const stage,planet,pLine;
	
	constructor(dispatchFunc,_stage){
		stage=_stage;
		let pGeometry,pLineGeometry,pMaterial,pLineMaterial;
		let winW,winH;
		const tree,buil;
		const earthObj3D=new THREE.Object3D();
		stage.add(earthObj3D);
		earthObj3D.scale.set(0.3,0.3,0.3);
		//earthObj3D.rotation.y=1.1*Math.PI;
		//earthObj3D.rotation.x=-0.0*Math.PI;
		//earthObj3D.rotation.z=0.4*Math.PI;
		let defaultQuaternion=earthObj3D.quaternion.clone();

		earthObj3D.rotation.y=-0.55*Math.PI;
		earthObj3D.rotation.x=-0.2*Math.PI;
		earthObj3D.rotation.z=0.3*Math.PI;

		let firstQuaternion=earthObj3D.quaternion.clone();


		EARTH.earthObj3D=earthObj3D;

		const uvMap=new THREE.TextureLoader().load("img/UVcheck.jpg");
		const earthMap=new THREE.TextureLoader().load("img/earth.jpg");
		const yamaMap=new THREE.TextureLoader().load("img/yama.jpg");
		const yamaMask=new THREE.TextureLoader().load("img/yamamask.jpg");
		const yamaEmissiveMask=new THREE.TextureLoader().load("img/yama_emissivemap.jpg")

		const kumoObj3D;

		let parts,bird,boat;
		

		
		uvMap.wrapS=THREE.MirroredRepeatWrapping;
		uvMap.wrapT=THREE.MirroredRepeatWrapping;
		//console.log(uvMap)
		uvMap.repeat=new THREE.Vector2(8,8);
		const uvMaterial=new THREE.MeshPhongMaterial({map:uvMap,color:0xffffff,side:THREE.DoubleSide,transparent:true,opacity:1,wireframe:false});

		pGeometry=new THREE.SphereBufferGeometry(EARTH.circleR,64,16);
		//pGeometry=new THREE.CylinderBufferGeometry(EARTH.circleR, EARTH.circleR, EARTH.circleR, 32)
		//pGeometry=new THREE.SphereBufferGeometry(EARTH.circleR,12,6);
		pLineGeometry=new THREE.CircleBufferGeometry(EARTH.circleR,Math.floor(32*Math.PI));
		pMaterial=new THREE.MeshPhongMaterial({color:0xbbddff,side:THREE.DoubleSide,transparent:true,opacity:1,wireframe:false});
		//console.log(pGeometry)
		//console.log(pMaterial)
		pMaterial.flatShading=true;
		pLineMaterial=new THREE.LineBasicMaterial({color:0x000000});

		planet=new THREE.Object3D();
		//stage.add(planet);

		getSphericalProjection(pGeometry)

		let planetMesh=new THREE.Mesh(pGeometry,uvMaterial);
		planet.add(planetMesh);


		let circleGdgeGeometry=new THREE.EdgesGeometry(pLineGeometry,20);
		pLine=new THREE.LineSegments(circleGdgeGeometry,pLineMaterial);

		
		//THREE_BASE.scene.add(pLine);
		
		/*
		const loadTree,loadTree2;
		let fbxLoader=new THREE.FBXLoader();
		fbxLoader.addParseEvent(secondLoad);
		fbxLoader.load("fbx/kiTest.fbx.zip",1,function(){});


		*/


		firstLoad();


		/*loadTree=new Promise(function(resolve,reject){
			let fbxLoader=new THREE.FBXLoader();
			fbxLoader.addParseEvent(loadEnd);
			fbxLoader.load("fbx/kiTest2C.fbx.zip",1,function(){});
			function loadEnd(loadObject){
				setTree(loadObject);
				resolve();
				secondLoad();
			}
		});*/
		let firstloadfbx,loadfbx,loadshader;

		function firstLoad(){
			bird=new Bird(loadEnd);
			function loadEnd(){
				//console.log("loadEnd1")
				secondLoad()
			}
		}

		function secondLoad(){
			boat=new Boat(loadEnd);

			function loadEnd(){
				//console.log("loadEnd2")
				thirdLoad();
			}
		}

		function thirdLoad(_firstloadfbx){
			//////////////////////////
			//　　　　　旗
			//////////////////////////


			parts=new Parts(loadEnd);

			function loadEnd(){
				fourthLoad();
			}
						

		}

		
		function fourthLoad(_firstloadfbx){
			firstloadfbx=_firstloadfbx;
			//trace("loadEnd2")

			//今の所、firstloadfbxは使用していない


				///////////////////////
				//
				//   CHKYU
				//
				////////////////////////


				let fbxLoader=new THREE.FBXLoader();
				fbxLoader.addParseEvent(loadFbx);
				fbxLoader.load("fbx/tikyuTest15_kinasi.fbx.zip",1,function(){});
				let loadCount=0;
				let _vertexShader,_fragmentShader,_colorVertexShader,_colorFragmentShader;
				let treeData;

				$.ajax({
		            type: "GET",
		            dataType:"text",//dataTypeを指定する
		            url:"shader/shader.txt",
		            success: function(data) {
		                _vertexShader=$("#vertexShader",$(data)).text();
		                _fragmentShader=$("#fragmentShader",$(data)).text();
		                _colorVertexShader=$("#colorVertexShader",$(data)).text();
		                _colorFragmentShader=$("#colorFragmentShader",$(data)).text();
		                _addVertexShader=$("#addVertexShader",$(data)).text();
		                _addFragmentShader=$("#addFragmentShader",$(data)).text();
		              //  loadshader=_loadshader;
		                loadFunc();
						//init();
		            }
		    	});
				$.ajax({
					type: "GET",
		            dataType:"json",//dataTypeを指定する
		            url:"fbx/treeData.json",
		            success: function(data) {
		            	//console.log("json=")
		            	//console.log(data)
		            	treeData=data;
		            	loadFunc();

		            }
		    	
		    	})

		    	function loadFbx(_loadfbx){
		    		//console.log("loadFbx");
		    		//console.log(_loadfbx);
		    		loadfbx=_loadfbx;

		    		loadFunc();
		    	}

		    	
				function loadFunc(){
					
					loadCount+=1;
					if(loadCount>=3){
						
						let shader={vertexShader:_vertexShader,fragmentShader:_fragmentShader,
							colorVertexShader:_colorVertexShader,colorFragmentShader:_colorFragmentShader,
							addVertexShader:_addVertexShader,addFragmentShader:_addFragmentShader
						};


						//console.log(loadfbx);


						//////////////////////////
						//　　　　　木
						//////////////////////////

						tree=new Tree(treeData,shader,"",earthObj3D,treeInitEnd);//木

//console.log("tree")
						//console.log(tree)
						let chikyu,mizu,kumo;

						//////////////////////////
						//　　　　　ビル
						//////////////////////////
						$.each(loadfbx.children,function(id,el){
							if(el.type=="SkinnedMesh"){
								//console.log("el.name="+el.name)
								if(el.name.match(/tikyu_mesh/)){
									chikyu=el.clone();//地球
								}else if(el.name.match(/kawa_mesh/)){
									mizu=el.clone()//水
								}else if(el.name.match(/kumo_mesh/)){
									kumo=el.clone();//雲
								}else if(el.name.match(/buil_mesh/)){
									buil=new Buil(el.clone(),shader,"",earthObj3D);//ビル
								}
							}

						})

						//console.log("buil="+buil)
						//console.log(buil)


						


						
						

						//stage.add(chikyu);

						


						//////////////////////////
						//　　　　　地球
						//////////////////////////


						//console.log("chikyu=");
						//console.log(chikyu)

						let chikyuObj3D=analizeLoadFbx(chikyu);
						//getSphericalProjection(chikyuObj3D.children[0].geometry)
						//console.log("chikyuObj3D=")
						//console.log(chikyuObj3D)

						chikyuObj3D.children[0].material.map=earthMap;
						earthMap.repeat=new THREE.Vector2(12,12);
						earthMap.wrapS=THREE.RepeatWrapping;
						earthMap.wrapT=THREE.RepeatWrapping;
						//chikyuObj3D.children[0].material.color=new THREE.Color(0.7,0.9,0.35);
						chikyuObj3D.children[0].material.color=new THREE.Color(0.75,0.75,0.7);
						chikyuObj3D.children[0].material.color=new THREE.Color(0x3344dd);
						chikyuObj3D.children[0].material.color.offsetHSL(-0.01,-0.2,-0.05);
						//chikyuObj3D.children[0].material.color.offsetHSL(-0.01,-0.15,-0.025);
						//chikyuObj3D.children[0].material.color=new THREE.Color(0.7,0.9,0.35);
						chikyuObj3D.children[0].material.emissive=new THREE.Color(1,1,1);

						chikyuObj3D.children[0].material.emissiveIntensity=-0.15;


						//test
						//chikyuObj3D.children[4].material.opacity=0.1;
						//chikyuObj3D.children[4].material.transparent=true;


						chikyuObj3D.children[0].receiveShadow=true;
						chikyuObj3D.name="chikyuObj3D";
						earthObj3D.add(chikyuObj3D);

						$.each(chikyuObj3D.children,(id,el)=>{
							//console.log(el.material.name)
							if(el.material.name.match(/yama/)){
								el.material.map=yamaMap;
								
								//el.material.color=new THREE.Color(0.7,0.9,0.35);
								//el.material.color=new THREE.Color(0.8,0.8,0.65);
								//el.material.color=new THREE.Color(0x2e327d);
								el.material.color=new THREE.Color(0x3344dd);
								//el.material.color.offsetHSL(-0.02,-0.2,-0.3);
								el.material.color.offsetHSL(-0.03,-0.2,-0.25);
								//let emissiveColor=new THREE.Color(0x3344dd).offsetHSL(-0.2,-0.4,-0.2);
								//let HSLColor=emissiveColor.getHSL({});
			
								let emissiveColor=new THREE.Color(0xddffdd);
								//emissiveColor.offsetHSL(0.1,0,0.2);


								el.material.emissive=emissiveColor;
								//el.material.emissive=new THREE.Color(1,1,1);
								el.material.emissiveMap=yamaEmissiveMask;
								el.material.emissiveIntensity=0.45;
								
							}

							if(el.material.name.match(/kawagisi/)){
								el.material=chikyuObj3D.children[0].material;
							}

							if(el.material.name.match(/iwa/)){
								el.material.color.offsetHSL(0.5,-0.4,-0.4);
								el.material.color=new THREE.Color(0.35,0.5,1);

							}
						})


						//////////////////////////
						//　　　　　水面
						//////////////////////////



						let mizuObj3D=analizeLoadFbx(mizu);

						mizuObj3D.children[0].material.map=uvMap;
						mizuObj3D.name="mizuObj3D";

						earthObj3D.add(mizuObj3D);

						let videoArea=$(".main__water")[0];
						
						videoArea.addEventListener("canplay",function(){
							//console.log("Rerady")
							//videoArea.play();
						})
						videoArea.play();
						let videoTexture=new THREE.VideoTexture(videoArea);
						
						videoTexture.minFilter=THREE.LinearFilter;

						mizuObj3D.children[0].material.map=videoTexture;
						mizuObj3D.children[0].material.color=new THREE.Color(0.7,1.0,1.0);
						mizuObj3D.children[0].material.emissive=new THREE.Color(0.5,0.35,0.25);
						mizuObj3D.children[0].material.emissiveIntensity=-0.5;
						mizuObj3D.children[0].receiveShadow=true;

						//test
						//mizuObj3D.children[0].material.opacity=0.2;
						//mizuObj3D.children[0].material.transparent=true;

						//videoTexture.wrapS=THREE.MirroredRepeatWrapping;
						//videoTexture.wrapT=THREE.MirroredRepeatWrapping;


						//////////////////////////
						//　　　　　雲
						//////////////////////////


						kumoObj3D=analizeLoadFbx(kumo);
						let kumoscale=0.95;
						kumoObj3D.scale.set(kumoscale,kumoscale,kumoscale);
						kumoObj3D.name="kumoObj3D";
						earthObj3D.add(kumoObj3D);
						kumoObj3D.children[0].material.color=new THREE.Color(0.965,0.995,0.995).offsetHSL(0.05,-0.5,-0.6);;
						kumoObj3D.children[0].material.emissive=new THREE.Color(0.5,0.5,0.5);
						kumoObj3D.children[0].material.emissiveIntensity=0.75;
						//kumoObj3D.children[0].material.transparent=true;
						//kumoObj3D.children[0].material.opacity=0.75;
						kumoObj3D.children[0].material.flatShading=false;

						kumoObj3D.children[0].castShadow=true;

						//test
						//kumoObj3D.children[0].material.opacity=0.2;
						//kumoObj3D.children[0].material.transparent=true;

						

						earthObj3D.add(parts.flagObj3d);
						parts.setEarthObj3D(earthObj3D);
						//_this.flagObj3d


						//////////////////////////
						//　　　　　鳥
						//////////////////////////
						bird.obj3D.name="bird.obj3D";

						earthObj3D.add(bird.obj3D);


						//////////////////////////
						//　　　　　ボート
						//////////////////////////

						boat.obj3D.name="boat.obj3D";

						earthObj3D.add(boat.obj3D);



						
					}
					
				}
				

		}

		function treeInitEnd(){
			//trace("loadEnd3")
			//$(window).trigger("resize");
			dispatchFunc();
		}

		function secondLoadEnd(){

			dispatchFunc();

		}

		function analizeLoadFbx(_obj){
			let obj3D=new THREE.Object3D();


			let name=_obj.name;

			/*console.log("analizeLoadFbx")
			console.log(_obj)*/
			


			let attrPosition=_obj.geometry.attributes.position;
			let attrNormal=_obj.geometry.attributes.normal;
			if(_obj.geometry.attributes.uv2){
				let attrUv=_obj.geometry.attributes.uv2;
			}else{
				let attrUv=_obj.geometry.attributes.uv;
			}
			let group=_obj.geometry.groups;


			let positionArray=attrPosition.array;
			let positionCount=attrPosition.count;
			let normalArray=attrNormal.array;
			if(attrUv){
				let uvArray=attrUv.array;
			}


			let materials=_obj.material;//配列


			for(var l=0;l<group.length;l++){
				let start=group[l].start;
				let count=group[l].count;
				let materialIndex=group[l].materialIndex;

				//console.log(l+":start="+start+",count="+count+",materialIndex="+materialIndex)

				let positions=new Float32Array(count*3);
				let normals=new Float32Array(count*3);
				if(uvArray){
					let uvs=new Float32Array(count*2);
				}

				for(var n=start;n<count+start;n++){
					let countZero=(n-start)*3;
					let countNow=n*3;
					let uvZero=(n-start)*2;
					let uvNow=n*2;
					if(l==1){
						//console.log(countZero+"->"+countNow)
					}
					positions[countZero]=positionArray[countNow];
					positions[countZero+1]=positionArray[countNow+1];
					positions[countZero+2]=positionArray[countNow+2];
					normals[countZero]=normalArray[countNow];
					normals[countZero+1]=normalArray[countNow+1];
					normals[countZero+2]=normalArray[countNow+2];
					if(uvArray){
						uvs[uvZero]=uvArray[uvNow];
						uvs[uvZero+1]=uvArray[uvNow+1];
					}
				}
				let newGeometry=new THREE.BufferGeometry();
				newGeometry.addAttribute('position',new THREE.BufferAttribute(positions,3));
				newGeometry.addAttribute('normal',new THREE.BufferAttribute(normals,3));
				if(uvs){
					newGeometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));
				}
				let material=((materials.length)?materials[materialIndex]:materials;

				material.flatShading=true;
				material.skinning=false;
				material.side=THREE.DoubleSide;
				//material.wireframe=true;


				if(name.match(/kumo/)){
					//material=new THREE.MeshPhongMaterial({color:0xff0000})
				}


				let mesh=new THREE.Mesh(newGeometry,material);
				//mesh.scale.set(0.3,0.3,0.3);

				if(name.match(/kumo/)){
					//material.emissiveIntensity=0.5;
					//material.side=THREE.DoubleSide;
					
				}

				obj3D.add(mesh);

				if(name.match(/kumo/)){
					//obj3D.add(mesh);
				}else if(l==1){
					
				}
			}

			return obj3D;
		}

		function getOrthographicProjection(geometry,reverse){
			geometry.computeBoundingBox();
			let positions=geometry.attributes.position.array;
			let boundingBox=geometry.boundingBox;
			let size=boundingBox.getSize(new THREE.Vector3());
			let virticiesQt=geometry.attributes.position.count;

			let uvs=new Float32Array(virticiesQt*2);

			for(var n=0;n<virticiesQt;n++){
				uvs[n*2]=(positions[n*3]-boundingBox.min.x)/size.x;
				if(reverse){
					uvs[n*2]=1-uvs[n*2];
					if(utils.Browser=="ie11"){

					}
				}

				if(utils.Browser=="ie11"){
					uvs[n*2+1]=(positions[n*3+1]-boundingBox.min.y)/size.y;

				}else{
				uvs[n*2+1]=1-(positions[n*3+2]-boundingBox.min.z)/size.z;
				}
			}

			geometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));
			
		}

		function getSphericalProjection(geometry){
			//疑似的に

			let positions=geometry.attributes.position.array;
			geometry.computeBoundingBox();
			let boundingBox=geometry.boundingBox;
			let size=boundingBox.getSize(new THREE.Vector3());

			let virticiesQt=geometry.attributes.position.count;
			let uvs=new Float32Array(virticiesQt*2);
			for(var n=0;n<virticiesQt;n++){
				let x=positions[n*3];
				let y=positions[n*3+1];
				let z=positions[n*3+2];
				var v3=new THREE.Vector3(x,y,z).normalize();


				let yEuler=Math.atan2((1-0.25*(Math.abs(v3.z)))*v3.z,Math.sin(v3.x*0.5*Math.PI));

				if(z>=0 && x>=0){
					yEuler=0.5*Math.PI-Math.atan2(z,x);
				}else if(z<0 && x>=0){
					yEuler=0.5*Math.PI-Math.atan2(z,x);
				}else if(z<0 && x<0){
					yEuler=Math.PI*.5-Math.atan2(z,x);
				}else if(z>=0 && x<0){
					yEuler=Math.PI*2.5-Math.atan2(z,x);
				}


				uvs[n*2]=yEuler/(2*Math.PI);
				
				uvs[n*2+1]=Math.abs((positions[n*3+1]-boundingBox.min.y)/size.y);

			}

			geometry.addAttribute('uv',new THREE.BufferAttribute(uvs,2));

		}
		this.mouseDownHl=(_x,_y)=>{
			if(parts){
				parts.mouseDownHl(_x,_y);
			}
		}

		this.mouseMoveHl=(_x,_y)=>{
			//trace2("earth.js mouseMoveHl,"+[_x,_y])
			if(parts){
				parts.mouseMoveHl(_x,_y);
			}
		}

		this.mouseUpHl=()=>{
			if(parts){
				parts.mouseUpHl();
			}
		}

		this.clickHl=(mp)=>{
			parts.clickHl(mp);
		};

		let kumospeed=0.0005;

		this.resizeHl=(_w,_h)=>{
			winW=_w;
			winH=_h;
			if(parts){
	        	parts.resizeHl(_w,_h);
	        }
		}

		let startZoomState;

		this.startZoom=()=>{
			startZoomState=true;
			//console.log("startZoom")
		}

		let defaultSetCount=0;
		let defaultSetCountMax=150;
		let defaultSetCountdelay=125;
		let updateCount=0;
		let updateCountdelay=10;
		let updateCountMax=200;
		let updateScaleTarget;
		//let updateKey1=0.6;
		//let updateKey2=0.8;
		let updateKey1=0.4;
		let updateKey2=0.55;
		let updateKey3=0.7;
		let updateKey4=0.85;
		//let updateScaleTarget1=1.05;
		//let updateScaleTarget2=0.98;
		let updateScaleTarget1=1.1;
		let updateScaleTarget2=0.97;
		let updateScaleTarget3=1.015;
		let updateScaleTarget4=0.995;
		let scaleRatio,updateRatio,updateRatio2;

		this.update=function(){
			
	        var R=Math.asin(EARTH.circleR/THREE_BASE.camera.position.z);//接線と、なす角
	        var p=EARTH.circleR*Math.sin(R);
	        var q=Math.sqrt(Math.pow(EARTH.circleR,2)-Math.pow(p,2));
	        var scale=(q/EARTH.circleR);

	       
	        //pLine.scale.set(scale,scale,scale);
	        //pLine.position.z=p;


	        if(tree){
	        	tree.update();
	        	
	        }

	        if(startZoomState){
	        	updateCount+=1;
	        	//tree.update();

	        	if(updateCount>=updateCountdelay && updateCount<=updateCountMax+updateCountdelay){
	        		updateRatio=Math.pow((updateCount-updateCountdelay)/updateCountMax,.75);
	        		if(updateRatio<updateKey1){
	   					updateRatio2=utils.getYoyoRatio(updateRatio/updateKey1);
	        			scaleRatio=updateScaleTarget1*(1*updateRatio2+MAIN.firstBaseScale*(1-updateRatio2));

	        		}else if(updateRatio<updateKey2){
	        			updateRatio2=utils.getYoyoRatio((updateRatio-updateKey1)/(updateKey2-updateKey1));
	        			scaleRatio=updateScaleTarget1+(updateScaleTarget2-updateScaleTarget1)*updateRatio2;

	        		}else if(updateRatio<updateKey3){
	        			updateRatio2=utils.getYoyoRatio((updateRatio-updateKey2)/(updateKey3-updateKey2));
	        			scaleRatio=updateScaleTarget2+(updateScaleTarget3-updateScaleTarget2)*updateRatio2;

	        		}else if(updateRatio<updateKey4){
	        			updateRatio2=utils.getYoyoRatio((updateRatio-updateKey3)/(updateKey4-updateKey3));
	        			scaleRatio=updateScaleTarget3+(updateScaleTarget4-updateScaleTarget3)*updateRatio2;

	        		}else{
	        			updateRatio2=utils.getYoyoRatio((updateRatio-updateKey4)/(1-updateKey4));
	        			scaleRatio=updateScaleTarget4+(1-updateScaleTarget4)*updateRatio2;

	        		}
	        		
	        		var lotationR=updateRatio*Math.PI*2;
	        		var moveRatio=Math.pow((1-updateRatio),3)*(scaleRatio-MAIN.firstBaseScale)/(1-MAIN.firstBaseScale);

	        		THREE_BASE.moveStage.position.x=moveRatio*225*Math.cos(lotationR*1.25);
	        		THREE_BASE.moveStage.position.y=moveRatio*225*Math.sin(-lotationR);
	        		//console.log("updateRatio="+updateRatio);
	        		//console.log("(moveRatio="+moveRatio)
	        		//console.log(Math.sin(-lotationR))
	        		//console.log(THREE_BASE.moveStage.position.x+","+THREE_BASE.moveStage.position.y)

	        		
	        		if(updateRatio>=updateKey1){
	        			MAIN.canvasArea.removeAttr("style");
	        			//THREE_BASE.loadEndUpdate=1;
	        		}else{
		        		MAIN.canvasArea.css("filter","blur("+(1-updateRatio/updateKey1)*10+"px)");
		        		//THREE_BASE.loadEndUpdate=(1-updateRatio);
		        	}
		        	//THREE_BASE.resizeHl();
	        		//console.log("scaleRatio="+scaleRatio)

	        		THREE_BASE.moveStage.scale.set( scaleRatio, scaleRatio,scaleRatio);
	        		THREE_BASE.moveStageMoveEnd=true;
	        	}

	        }

	        if(buil){
	        	buil.update();
	        }

	        if(parts){
	        	parts.update();
	        }

	        if(bird){
	        	bird.update();
	        }

	        if(boat){
	        	boat.update();
	        }

	        //console.log(buil)

	        if(kumoObj3D){
	        	kumoObj3D.rotation.y+=kumospeed;
	        	kumoObj3D.rotation.x+=kumospeed*0.2;
	        }

	        defaultSetCount+=1;

	        if(defaultSetCount>=defaultSetCountdelay && defaultSetCount-defaultSetCountdelay<=defaultSetCountMax){
	        	let defaultSetRatio=(defaultSetCount-defaultSetCountdelay)/defaultSetCountMax;
	        	defaultSetRatio=utils.getYoyoRatio(defaultSetRatio);
				//earthObj3D.quaternion.copy(firstQuaternion).slerp(defaultQuaternion,defaultSetRatio)

	        }


	        

		}


	}

}